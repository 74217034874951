import useGeneralStyles from "@components/Services/styles";
import ServiceButton from "@components/Services/ServiceButton/ServiceButton";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
import useStyles from "./recurringPayments.style";
import NewCredit from "@components/Services/Credits/NewCredit/NewCredit";
import Consolidate from "@components/Services/Credits/Consolidate/Consolidate";
import RefinanceCredit from "@components/Services/Credits/Refinance/Refinance";

interface recurringPaymentsProps {
  setStepSearching: (value: number) => void;
  recurringPaymentCategory: number;
  setPathRoute: (value: JSX.Element) => void;
}

const RecurrentPaymentsSearching: React.FC<recurringPaymentsProps> = ({
  setStepSearching,
  recurringPaymentCategory,
  setPathRoute,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  useEffect(() => {
    setPathRoute(<></>);
  }, []);

  return (
    <section className={classesGeneral.container}>
      <div className={classesGeneral.title}>
        Aquí podemos analizar todas tus cuentas y pagos recurrentes como
        Netflix, Gimnasio, Internet, Celular y más.
      </div>
      <div className={classesGeneral.title}>
        <span>
          Analizamos tus planes actuales y buscamos mejores opciones para ti
        </span>
        , para que pagues menos por lo que ya tienes.
      </div>
      <div className={classesGeneral.principalTitle}>
        ¿Qué pagos quieres analizar?
      </div>
      <div className={classes.containerItems}>
        {list.map((item) => (
          <ServiceButton
            onClick={() => {
              setStepSearching(item.id);
            }}
            key={item.id}
            name={item.name}
          />
        ))}
      </div>
    </section>
  );
};
export default RecurrentPaymentsSearching;

const list = [
  {
    id: 0,
    name: "Suscripciones",
  },
  {
    id: 1,
    name: "Plan de Internet",
  },
  {
    id: 2,
    name: "Plan de Celular",
  },
  {
    id: 3,
    name: "Gimnasio",
  },
  {
    id: 4,
    name: "Productos financieros",
  },
];
