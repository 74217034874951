import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
    "& .input": {
      display: "flex",
      flexDirection: "column",
    },
  },
  general: {
    margin: "0 auto",
  },
  listServices: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1.5rem 2.5rem",
  },
  data: {
    margin: "0 auto",
    width: "206px",
  },
  containerButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
    "& .input": {
      width: "17.8rem!important",
      maxWidth: "auto",
    },
  },
  // perfecto
  subContainerCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "25rem",
    margin: "0 auto",
  },
  subContainerCenterTrip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "space-between",
    width: "38rem",
    margin: "0 auto",
  },
  flexTrip: {
    display: "grid",
    justifyContent: "space-between",
    "&>div": {
      minWidth: "17.75rem",
    },
  },
  flexList: {
    display: "flex",
    gap: "1.9rem",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
    },
    "& .inpuShort": {
      width: "8.1rem",
    },
    "& .inputLarge": {
      width: "17.8rem",
    },
  },
  containerButtonEnd: {
    display: "flex",
    justifyContent: "end",
    marginRight: "1rem",
    marginTop: "4rem",
    [theme.breakpoints.down(1240)]: {
      marginRight: "0rem",
      justifyContent: "center",
    },
    "& button": {
      width: "17.8rem",
    },
  },
}));
export default useStyles;
