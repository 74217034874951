import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./internet.style";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useApolloClient } from "@apollo/client";
import { LoadingContext } from "@context";
import { useCurrentUser } from "@apollo";
import { useForm } from "@hooks";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface InternetProps {
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const Internet: React.FC<InternetProps> = ({ setPathRoute, setFinishSave }) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [form] = useForm();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();
  const [compania, setCompania] = useState<string>();
  const [namePlan, setNamePlan] = useState<string>();
  const [value, setValue] = useState<string>();

  useEffect(() => {
    setPathRoute(
      <>
        <span>
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Plan de internet</span>
      </>
    );
  }, []);

  const onSend = useCallback(() => {
    form.recurringPayments = {
      analisis: "Plan de Internet",
      informacion: {
        compania,
        plan: namePlan,
        valor: value,
      },
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.recurringPayments;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, compania, namePlan, value]);

  const disabled = compania && namePlan && value;

  return (
    <section className={classesGeneral.container}>
      <div className={classes.flexList}>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>Compañía</span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setCompania(e.target.value as string);
            }}
            value={compania}
          />
        </div>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>
            Nombre de tu plan
          </span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setNamePlan(e.target.value as string);
            }}
            value={namePlan}
          />
        </div>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>Valor</span>
          <TextInput
            className="inputLarge"
            type={"number"}
            onChange={(e) => {
              setValue(e.target.value as string);
            }}
            value={value}
          />
        </div>
      </div>
      <div className={classes.containerButtonEnd}>
        <ButtonPrimary
          disabled={!disabled}
          noDegraded
          onClick={onSend}
          text="Enviar"
        />
      </div>
    </section>
  );
};
export default Internet;
