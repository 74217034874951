import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme>((theme) => ({
  containerText: {
    width: "21.5rem",
    color: theme.palette.text.secondary,
    margin: "2rem auto 0",
  },
  containerText2: {
    width: "17rem",
    color: theme.palette.text.secondary,
    margin: "2rem auto 0",
    fontSize: "0.875rem",
  },
  center: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "0 auto 2rem",
  },
  select: {
    minWidth: "14rem",
  },
  flexTrip: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto 2rem",
  },
  secondaryTitle: {
    fontSize: "1.25rem",
    fontWeight: 300,
    margin: "0 auto 1rem",
  },
  containerSelect: {
    margin: "0 auto",
  },
  containerList: {
    width: "100%",
  },
  containerItem: {
    width: "100%",
  },
  item: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 auto 1rem",
    justifyItems: "center",
  },
  itemName: {
    fontSize: "0.875rem",
    fontWeight: 300,
  },
  itemAmount: {
    fontSize: "0.875rem",
    fontWeight: 300,
  },
  line: {
    width: "100%",
    height: "1px",
    backgroundColor: theme.palette.text.secondary,
    margin: "0.5rem auto 1rem",
  },

  button: {
    width: "17.8rem",
    fontWeight: 300,
    margin: "0 auto",
    display: "flex",
  },
}));
export default useStyles;
