import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./gym.style";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useForm } from "@hooks";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface GymProps {
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const Gym: React.FC<GymProps> = ({ setPathRoute, setFinishSave }) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();

  const [nameGym, setNameGym] = useState<string>();
  const [ubication, setUbication] = useState<string>();
  const [value, setValue] = useState<string>();

  const [form] = useForm();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  useEffect(() => {
    setPathRoute(
      <>
        <span>
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Gimnasio</span>
      </>
    );
  }, []);

  const onSend = useCallback(() => {
    form.recurringPayments = {
      analisis: "Gimnasio",
      informacion: {
        "nombre gimnasio": nameGym,
        ubicación: ubication,
        valor: value,
      },
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.recurringPayments;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, nameGym, ubication, value]);

  const disabled = nameGym && ubication && value;

  return (
    <section className={classesGeneral.container}>
      <div className={classes.flexList}>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>
            Nombre de tu Gimnasio
          </span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setNameGym(e.target.value as string);
            }}
            value={nameGym}
          />
        </div>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>Ubicación</span>
          <TextInput
            className="inputLarge"
            type={"text"}
            onChange={(e) => {
              setUbication(e.target.value as string);
            }}
            value={ubication}
          />
        </div>
        <div className={classes.flexTrip}>
          <span className={classesGeneral.secondaryTitle}>Valor</span>
          <TextInput
            className="inputLarge"
            type={"number"}
            onChange={(e) => {
              setValue(e.target.value as string);
            }}
            value={value}
          />
        </div>
      </div>
      <div className={classes.containerButtonEnd}>
        <ButtonPrimary
          disabled={!disabled}
          noDegraded
          onClick={onSend}
          text="Enviar"
        />
      </div>
    </section>
  );
};
export default Gym;
