import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./financialProducts.style";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Select } from "@components";
import { FINANCIAL_PRODUCTS_OPTIONS } from "@components/Inputs";
import { useForm } from "@hooks";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { useApolloClient } from "@apollo/client";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface FinancialProducts {
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const FinancialProducts: React.FC<FinancialProducts> = ({
  setPathRoute,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [financialProducts, setFinancialProducts] = useState<
    { value: string; amount: number }[]
  >([]);

  const [form] = useForm();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const createFinancialProduct = useCallback(
    (value: string, amount: number) => {
      // only add if it doesn't exist
      if (
        !financialProducts.find(
          (financialProduct) => financialProduct.value === value
        )
      ) {
        setFinancialProducts((prev) => [...prev, { value, amount }]);
      }
    },
    [setFinancialProducts]
  );

  const deleteFinancialProduct = useCallback(
    (value: string) => {
      setFinancialProducts((prev) =>
        prev.filter((financialProduct) => financialProduct.value !== value)
      );
    },
    [setFinancialProducts]
  );

  const handleChangeAmount = useCallback(
    (value: string, amount: number) => {
      setFinancialProducts((prev) =>
        prev.map((financialProduct) =>
          financialProduct.value === value
            ? { ...financialProduct, amount }
            : financialProduct
        )
      );
    },
    [setFinancialProducts]
  );

  useEffect(() => {
    setPathRoute(
      <>
        <span>
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Productos Financieros</span>
      </>
    );
  }, []);

  const onSend = useCallback(() => {
    form.recurringPayments = {
      analisis: "Productos Financieros",
      informacion: financialProducts,
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.recurringPayments;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, financialProducts]);

  const disabled = financialProducts[0];

  return (
    <section className={classesGeneral.container}>
      <div className={`${classes.center} ${classes.containerText}`}>
        Agrega todos los productos financieros que quieras y lo que estás
        pagando por ellos
      </div>
      <div className={classes.flexTrip}>
        <span className={classesGeneral.secondaryTitle}>
          Selecciona tus Suscripciones
        </span>
        <div className={classes.containerSelect}>
          <Select
            className={classes.select}
            options={FINANCIAL_PRODUCTS_OPTIONS}
            onChange={(e) => {
              createFinancialProduct(e.target.value as string, 0);
            }}
          />
        </div>
      </div>
      <div className={classes.containerList}>
        {financialProducts[0] && (
          <div className={classes.containerItem}>
            <div className={classes.item}>
              <div className={classes.itemName}>Suscripción</div>
              <div className={classes.itemAmount}>¿Cuánto pagas?</div>
            </div>
          </div>
        )}
        {financialProducts.map((financialProduct) => (
          // agregar linea entre cada item
          <div key={financialProduct.value} className={classes.containerItem}>
            <div className={classes.item}>
              <div className={classes.itemName}>{financialProduct.value}</div>
              <div className={classes.itemAmount}>
                <TextInput
                  value={financialProduct.amount}
                  onChange={(e) =>
                    handleChangeAmount(
                      financialProduct.value,
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <IconButton
                onClick={() => {
                  deleteFinancialProduct(financialProduct.value);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* if not last item, add line */}
            {financialProduct !==
              financialProducts[financialProducts.length - 1] && (
              <div className={classes.line} />
            )}
          </div>
        ))}
      </div>
      <ButtonPrimary
        className={classes.button}
        noDegraded
        disabled={!disabled}
        onClick={onSend}
        text="Enviar"
      />
    </section>
  );
};
export default FinancialProducts;
