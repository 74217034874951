import React, { useCallback, useContext, useState } from "react";
import { navigate, PageProps } from "gatsby";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { SEO } from "@components";
import { LoadingContext } from "@context";
import { getAuth } from "firebase/auth";
import LayoutServices from "@components/Services/Layout/LayoutServices";
import { Route } from "@interfaces";
import RecurringPaymentsSearching from "@components/Services/RecurringPayments/RecurringPayments/recurringPayments";
import Suscriptions from "@components/Services/RecurringPayments/Suscriptions/suscriptions";
import Gym from "@components/Services/RecurringPayments/Gym/gym";
import Phone from "@components/Services/RecurringPayments/Phone/phone";
import Internet from "@components/Services/RecurringPayments/Internet/internet";
import FinancialProducts from "@components/Services/RecurringPayments/FinancialProducts/financialProducts";

import Notification48 from "@components/Services/Credits/Credits/Credits/Searching/Notification48hs/Notification48";
import Notification24 from "@components/Services/Credits/Credits/Credits/Searching/Notification24hs/Notification24";
import Notification from "@components/Services/Credits/Credits/Credits/Searching/Notification/Notification";
import HeaderServices from "@components/Services/Layout/Header/Header";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { useAuth } from "@hooks";

const SaveByRokinPage: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });

  const classes = useStyles();
  const { hideLoader } = useContext(LoadingContext);
  const currentUser = getAuth().currentUser;

  const [recurringPaymentCategory, setRecurringPaymentCategory] = useState(-1);
  const [pathRoute, setPathRoute] = useState<JSX.Element>();
  const [stepSearching, setStepSearching] = useState(-1);
  const [stepSubCategory, setStepSubCategory] = useState(-1);
  const isMobile = useMediaQuery("(max-width: 1200px)");

  const [finishSave, setFinishSave] = useState({
    Notification48hs: false,
    Notification24hs: false,
    Notification: false || undefined,
  });
  const [numberStep, setNumberStep] = useState(-1);

  const onBackClick = useCallback(() => {
    recurringPaymentCategory === -1 && navigate(Route.services);
    recurringPaymentCategory !== -1 && setStepSearching(-1);
    recurringPaymentCategory !== -1 && setRecurringPaymentCategory(-1);
    numberStep !== -1 && setNumberStep(-1);
  }, [recurringPaymentCategory, stepSearching, numberStep]);

  let StepSearchingSelect;

  switch (stepSearching) {
    case -1:
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              navigate(Route.services);
            }}
          />
          <RecurringPaymentsSearching
            recurringPaymentCategory={recurringPaymentCategory}
            setStepSearching={setStepSearching}
            setPathRoute={setPathRoute}
          />
        </>
      );
      break;
    case 0:
      recurringPaymentCategory === -1 && setRecurringPaymentCategory(0);
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              setStepSearching(-1);
            }}
            steps={["Suscripciones"]}
          />
          <Suscriptions
            setFinishSave={setFinishSave}
            setPathRoute={setPathRoute}
          />
        </>
      );
      break;
    case 1:
      recurringPaymentCategory === -1 && setRecurringPaymentCategory(1);
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              setStepSearching(-1);
            }}
            steps={["Plan de Internet"]}
          />
          <Internet setFinishSave={setFinishSave} setPathRoute={setPathRoute} />
        </>
      );
      break;
    case 2:
      recurringPaymentCategory === -1 && setRecurringPaymentCategory(2);
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              setStepSearching(-1);
            }}
            steps={["Plan de celular"]}
          />
          <Phone setFinishSave={setFinishSave} setPathRoute={setPathRoute} />;
        </>
      );

      break;
    case 3:
      recurringPaymentCategory === -1 && setRecurringPaymentCategory(3);
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              setStepSearching(-1);
            }}
            steps={["Gimnasio"]}
          />
          <Gym setFinishSave={setFinishSave} setPathRoute={setPathRoute} />;
        </>
      );
      break;
    case 4:
      recurringPaymentCategory === -1 && setRecurringPaymentCategory(4);
      StepSearchingSelect = (
        <>
          <HeaderServices
            title="PAGOS RECURRENTES"
            onBackClick={() => {
              setStepSearching(-1);
            }}
            steps={["Productos Financieros"]}
          />
          <FinancialProducts
            setFinishSave={setFinishSave}
            setPathRoute={setPathRoute}
          />
          ;
        </>
      );
      break;
    default:
      break;
  }
  return (
    <>
      <SEO />
      {finishSave.Notification48hs && <Notification48 />}
      {finishSave.Notification24hs && <Notification24 secondText />}
      {finishSave.Notification && <Notification />}

      {!finishSave.Notification48hs &&
        !finishSave.Notification &&
        !finishSave.Notification24hs && (
          <LayoutServices
            state={pathRoute}
            title={"PAGOS RECURRENTES"}
            onBackClick={onBackClick}
            key="Bottom"
            hiddenHeader={!isMobile}
            activeServices
          >
            {StepSearchingSelect}
          </LayoutServices>
        )}
      {isMobile && (
        <LayoutMobileInvestments activeServices>
          <></>
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default SaveByRokinPage;

const useStyles = makeStyles((theme) => ({}));
