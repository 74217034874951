import useGeneralStyles from "@components/Services/styles";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useStyles from "./suscriptions.style";
import { ButtonPrimary } from "@components/Reusables";
import { TextInput } from "@components/Inputs";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Select } from "@components";
import { SUSCRIPTIONS_OPTIONS } from "@components/Inputs";
import { useForm } from "@hooks";
import { useApolloClient } from "@apollo/client";
import { useCurrentUser } from "@apollo";
import { LoadingContext } from "@context";
import { sendContactFormService } from "apollo/contact_form/sendContactFormsService.mutation";

interface SuscriptionsProps {
  setPathRoute: (value: JSX.Element) => void;
  setFinishSave: (value: any) => void;
}

const Suscriptions: React.FC<SuscriptionsProps> = ({
  setPathRoute,
  setFinishSave,
}) => {
  const classesGeneral = useGeneralStyles();
  const classes = useStyles();
  const [suscriptions, setSuscriptions] = useState<
    { value: string; amount: number }[]
  >([]);
  const [form] = useForm();
  const { user: currentUser } = useCurrentUser();
  const { showLoader, hideLoader } = useContext(LoadingContext);
  const client = useApolloClient();

  const createSuscription = useCallback(
    (value: string, amount: number) => {
      if (!suscriptions.find((suscription) => suscription.value === value)) {
        setSuscriptions((prev) => [...prev, { value, amount }]);
      }
    },
    [setSuscriptions]
  );

  const deleteSuscription = useCallback(
    (value: string) => {
      setSuscriptions((prev) =>
        prev.filter((suscription) => suscription.value !== value)
      );
    },
    [setSuscriptions]
  );

  const handleChangeAmount = useCallback(
    (value: string, amount: number) => {
      setSuscriptions((prev) =>
        prev.map((suscription) =>
          suscription.value === value ? { ...suscription, amount } : suscription
        )
      );
    },
    [setSuscriptions]
  );

  useEffect(() => {
    setPathRoute(
      <>
        <span>
          <ArrowForwardIosIcon />
        </span>
        <span className="spaceCredit">Suscripciones</span>
      </>
    );
  }, []);

  const onSend = useCallback(() => {
    form.recurringPayments = {
      analisis: "Suscripciones",
      informacion: suscriptions,
    };
    setFinishSave({
      Notification48hs: false,
      Notification24hs: true,
      Notification: false,
    });

    showLoader("contact modal");
    const name = currentUser?.name || "";
    const email = currentUser?.email || "";
    const data: JSON = form.recurringPayments;

    sendContactFormService(
      {
        name,
        email,
        data,
      },
      client
    ).then(() => {
      hideLoader("contact modal");
    });
  }, [currentUser?.name, form, suscriptions]);

  return (
    <section className={classesGeneral.container}>
      <div className={`${classes.center} ${classes.containerText}`}>
        Agrega todas las suscripciones que quieras y lo que estás pagando por
        ellas
      </div>
      <div className={classes.flexTrip}>
        <span className={classesGeneral.secondaryTitle}>
          Selecciona tus Suscripciones
        </span>
        <div className={classes.containerSelect}>
          <Select
            className={classes.select}
            options={SUSCRIPTIONS_OPTIONS}
            onChange={(e) => {
              createSuscription(e.target.value as string, 0);
            }}
          />
        </div>
      </div>

      <div className={classes.containerList}>
        {suscriptions[0] && (
          <div className={classes.containerItem}>
            <div className={classes.item}>
              <div className={classes.itemName}>Suscripción</div>
              <div className={classes.itemAmount}>¿Cuánto pagas?</div>
            </div>
          </div>
        )}

        {suscriptions.map((suscription) => (
          // agregar linea entre cada item
          <div key={suscription.value} className={classes.containerItem}>
            <div className={classes.item}>
              <div className={classes.itemName}>{suscription.value}</div>
              <div className={classes.itemAmount}>
                <TextInput
                  value={suscription.amount}
                  onChange={(e) =>
                    handleChangeAmount(
                      suscription.value,
                      Number(e.target.value)
                    )
                  }
                />
              </div>
              <IconButton
                onClick={() => {
                  deleteSuscription(suscription.value);
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
            {/* if not last item, add line */}
            {suscription !== suscriptions[suscriptions.length - 1] && (
              <div className={classes.line} />
            )}
          </div>
        ))}
      </div>
      <ButtonPrimary
        className={classes.button}
        noDegraded
        disabled={!suscriptions[0]}
        onClick={onSend}
        text="Enviar"
      />
    </section>
  );
};
export default Suscriptions;
